import React from "react";
import { FiCodesandbox } from "react-icons/fi";
import styled from "styled-components";
import Card from "./Slider";
import { Slide } from "react-awesome-reveal";
import { Zoom } from "react-awesome-reveal";
import { FaReact } from "react-icons/fa6";
import { FaHtml5 } from "react-icons/fa6";

const Services = () => {
  return (
    <Container id="project">
      <Slide direction="down">
      <Zoom style={{textAlign:'center'}}>
             <h1>Recent <span className="green">Projects</span></h1>
             <p>The recent project i have done through my life</p>
         </Zoom>
      </Slide>
      <Cards>
        <Slide direction="right">
          <Card
            Icon={FaReact}
            title={"Silgrace Tech"}
            disc={`Developed and contributed to the creation of our company website using React and various components, showcasing collaborative teamwork and technical proficiency `}
            link={'https://silgrace.netlify.app/'}
          />
        </Slide>
        <Slide direction="down">
          <Card
            Icon={FiCodesandbox}
            title={"TeachBox"}
            disc={`Contributing the development of Teach Box, a website designed to aid the education of students in African villages. Built using React and Node.js, this project reflects our team work`}
            link={'https://teachbox.ac/login'}
          />
        </Slide>
        <Slide direction="left">
          <Card
            Icon={FaHtml5}
            title={"Admin Dashboard"}
            disc={`Designed and implemented an administrative and sales dashboard using HTML5, JavaScript, and CSS. Independently developed to streamline management and enhance sales insights.`}
            link={'https://jouharkabeer.github.io/vst/'}
          />
        </Slide>
      </Cards>
    </Container>
  );
};

export default Services;

const Container = styled.div`
  width: 80%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 3rem 0;
  @media (max-width: 840px) {
    width: 90%;
  }

  h1 {
    padding-top: 1rem;
  }
`;
const Cards = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  margin-top: 4rem;
  gap: 1rem;
`;
