import React from 'react'
import styled from 'styled-components';

const Card = (props) => {
    const { Icon, disc, title, link } = props;
  return (
    <Container>
        <span className='green'><Icon/></span>
        <h1>{title}</h1>
        <p>{disc}</p>
        <button><a href={link}> Demo </a></button>
    </Container>
  )
}

export default Card;

const Container = styled.div`
    width: 100%;
    background: linear-gradient(159deg, rgb(15, 85, 88) 0%, rgb(43, 53, 53) 100%);
    padding: 1rem;
    text-align: center;
    span{
        font-size: 4rem;
    }
    
    h1{
        font-size: 1.2rem;
        padding-bottom: 1rem;
    }

    p{
        font-size: 0.8rem;
    }

    a{
      text-decoration:none;
      color:#fff;
    }

    button {
      padding: 0.7rem 2rem;
      margin-top: 3rem;
      cursor: pointer;
      background-color: #0db0b6;
      border: none;
      color: #fff;
      font-weight: 500;
      filter: drop-shadow(0px 10px 10px #01be9551);
      :hover {
        filter: drop-shadow(0px 10px 10px #01be9570);
      }
    }
`