import {
  VerticalTimeline,
  VerticalTimelineElement
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import styled from "styled-components";
import { FaInnosoft } from "react-icons/fa";
import { Divider } from "@mui/material";
import { MdOutlineCastForEducation } from "react-icons/md";
import { Slide } from "react-awesome-reveal";

export default function client() {
  return (
    <Container id="client">
      <h1>My <span className="green"> TimeLine </span></h1>
      <div className="empty">Life Journey</div>

      <Slide direction="left">
      <VerticalTimeline>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: 'linear-gradient(159deg, rgb(45, 45, 58) 0%, rgb(43, 43, 53) 100%)'
            , color: "#fff" }}
          contentArrowStyle={{ borderRight: "7px solid  rgb(33, 150, 243)" }}
          date="Jul / 2023 - present"
          iconStyle={{ background: "#0db0b6", color: "#fff" }}
          icon={<FaInnosoft />}
        >
          <h3 className="vertical-timeline-element-title">Junior Developer</h3>
          <h4 className="vertical-timeline-element-subtitle">Silgrace Tech, Ernakulam</h4>
          <p>
            I am currently working as Junior software Developer,
            focused on front-end developing
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: 'linear-gradient(159deg, rgb(45, 45, 58) 0%, rgb(43, 43, 53) 100%)'
            , color: "#fff" }}
          contentArrowStyle={{ borderRight: "7px solid  rgb(33, 150, 243)" }}
          date="Dec / 2022 - present"
          iconStyle={{ background: "#0db0b6", color: "#fff" }}
          icon={<FaInnosoft />}
        >
          <h3 className="vertical-timeline-element-title">Software Developer</h3>
          <h4 className="vertical-timeline-element-subtitle">Axtella Global, Saudi Arabia</h4>
          <p>
            I am currently working as software Developer,
            focused on Web developing
          </p>
        </VerticalTimelineElement>
        <span className="green"><h3>Education</h3></span>
        <Divider style={{ backgroundColor: '#fff', height : 4 }} />
        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          contentStyle={{ background: 'linear-gradient(159deg, rgb(45, 45, 58) 0%, rgb(43, 43, 53) 100%)'
            , color: "#fff" }}
          contentArrowStyle={{ borderRight: "7px solid  rgb(33, 150, 243)" }}
          date="2019 - 2023"
          iconStyle={{ background: "#0db0b6", color: "#fff" }}
          icon={<MdOutlineCastForEducation />}
        >
          <h3 className="vertical-timeline-element-title"> B.tech (CSE)</h3>
          <h4 className="vertical-timeline-element-subtitle">UCE, Thodupuzha</h4>
          <p>
          I hold a Bachelor's degree in Computer Science and Engineering, where I acquired a solid foundation in programming, algorithms, and software development methodologies.
          </p>
          <p>CGPA : 7.6</p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{     background: 'linear-gradient(159deg, rgb(45, 45, 58) 0%, rgb(43, 43, 53) 100%)'
            , color: "#fff" }}
          contentArrowStyle={{ borderRight: "7px solid  rgb(33, 150, 243)" }}
          date="2017 - 2019"
          iconStyle={{ background: "#0db0b6", color: "#fff" }}
          icon={<MdOutlineCastForEducation />}
        >
          <h3 className="vertical-timeline-element-title">HSE (CS)</h3>
          <h4 className="vertical-timeline-element-subtitle">SMV, Poonjar</h4>
          <p>
          Completed Computer Science in Higher Secondary Education at SMV HSS Poonjar, laying the groundwork for my journey in technology
          </p>
          <p>Percentage : 76%</p>
        </VerticalTimelineElement>
      </VerticalTimeline>
      </Slide>
    </Container>
  );
}


const Container = styled.div`
    width: 80%;
    max-width: 1280px;
    margin: 0 auto;
    padding: 4rem 0;

    @media(max-width:840px){
        width: 90%;
    }

    span{
        font-weight: 700;
        text-transform: uppercase;
    }

    h1{
        padding-top: 1rem;
        text-transform: capitalize;
    }

    .slick-list, .slick-slider, .slick-track{
        padding: 0;
    }

    .slick-dots{
        text-align: left;
        margin-left: 1rem;
    }

    .slick-dots li button:before{
        content: "";
    }

    .slick-dots li button{
        width: 9px;
        height: 4px;
        background: linear-gradient(159deg, rgb(45, 45, 58) 0%, rgb(43, 43, 53) 100%);
        padding: 0.1rem;
        margin-top: 1rem;
        transition: all 400ms ease-in-out;
        border-radius: 50px;
    }
    
    .slick-dots li.slick-active button{
        background: #0db0b6;
        width: 15px;
    }

    .slick-dots li{
        margin: 0;
    }
`
