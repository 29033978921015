import React from "react";
import { SiVorondesign } from "react-icons/si";
import { CgWebsite } from "react-icons/cg";
import { FaMobileAlt } from "react-icons/fa";
import styled from "styled-components";
import Card from "./Card";
import { Slide } from "react-awesome-reveal";

const Services = () => {
  return (
    <Container id="service">
      <Slide direction="down">
        <h4>
          My <span className="green">services</span>
        </h4>
        <h1>What I Do</h1>
      </Slide>
      <Cards>
        <Slide direction="left">
          <Card
            Icon={SiVorondesign}
            title={"Front-End Development"}
            disc={`Proficient in front-end development, 
                  specializing in React.js to build dynamic and interactive user interfaces with a strong emphasis on creating seamless and engaging web experiences.`}
          />
        </Slide>
        <Slide direction="up">
          <Card
            Icon={FaMobileAlt}
            title={"Application Development"}
            disc={`Possessing introductory skills in application development, 
                  with a focus on creating user-friendly and functional solutions, eager to expand knowledge in this dynamic field`}
          />
        </Slide>
        <Slide direction="right">
          <Card
            Icon={CgWebsite}
            title={"web designing"}
            disc={`Skilled in web design, with a focus on creating visually appealing and intuitive layouts, combining creativity and technical expertise to craft engaging online experiences.`}
          />
        </Slide>
      </Cards>
    </Container>
  );
};

export default Services;

const Container = styled.div`
  width: 80%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 3rem 0;
  @media (max-width: 840px) {
    width: 90%;
  }

  h1 {
    padding-top: 1rem;
  }
`;
const Cards = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  margin-top: 4rem;
  gap: 1rem;
`;
